<template>
    <div class="">
        <div class="flex justify-between flex-wrap items-center py-2">
            <div class="flex flex-wrap gap-2 py-2">
                <SearchBar @name-selected="nameSelectedHandeler">
                </SearchBar>
                <div v-if="selectedServerName !== ''" class="w-32 pr-5 text-xs">
                    Status
                    <v-select v-model="selectedLicenseStatus" :items="licenseStatusList" density="compact" rounded="0"
                        variant="outlined" placeholder="Select License Status"
                        style="padding-top: 0px;padding-bottom: 0px;">
                    </v-select>
                </div>
                <div v-if="selectedServerName !== ''" class="w-48 pr-5 text-xs">
                    Customer Name
                    <v-combobox v-model="selectedCustomerName" :items="customers" placeholder="Select" rounded="0"
                        variant="outlined" style="outline: none;" density="compact" :hide-no-data="false">
                        <template v-slot:no-data>
                            <v-list-item density>
                                <v-list-item-title density="compact" variant="disabled">
                                    <p class="text-sm text-gray-600 text-center">Not found</p>
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-combobox>
                </div>
                <div v-if="selectedServerName !== ''" class="w-32 pr-5 text-xs">
                    Expiring
                    <v-select v-model="selectedExpiringMode" :items="expiringModeList" density="compact" rounded="0"
                        variant="outlined" placeholder="Select Expiring Mode"
                        style="padding-top: 0px;padding-bottom: 0px;">
                    </v-select>
                </div>
            </div>
            <div v-if="selectedServerName !== ''">
                <v-btn icon="mdi-delete" size="small" style="outline: none;" @click="deleteLicenses"
                    class="mr-5 bg-dark text-light"></v-btn>
                <v-btn v-if="selectedServerName !== 'All'" icon="mdi-plus" size="small" style="outline: none;"
                    class="bg-dark text-light" data-bs-toggle="modal" data-bs-target="#licenseAddModal"
                    @click="addLicense"></v-btn>
            </div>
        </div>
        <!-- <div v-if="loading === true" class=" flex items-center h-[500px] justify-center" >
            <img src="../../../assets/loading.gif" class="w-[50px]"/>
        </div> -->
        <div v-if="loading === true" class=" flex items-center justify-center absolute custom-style " >
            <img src="../../../assets/loading.gif" class="w-[50px]"/>
        </div>
        <v-data-table :headers="headers" :items="Licenses" item-key="name" items-per-page="-1" id="tablecontainer1" hide-default-footer
            class="border h-[500px] bg-white text-black min-h-[500px]" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
            
            <template #item="{ item, index }">
                
                <tr :class="{ 'text-red': item.status !== 'ACTIVE' }">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <div @click="toggleSelection(item)" class="ml-auto align-self-center"
                            style="width: 20px; height: 20px; cursor: pointer;">

                            <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />
                        </div>
                    </td>
                    <td>
                        <div class="">
                            <div class="flex justify-between items-center bg-white px-3 py-1 text-black">
                            Server Online <v-icon icon="mdi-circle" size="15"
                                    :color="item.modem_conn_status ? 'green' : 'red'" class="mr-2"></v-icon>
                        </div>
                        <div class="flex justify-between items-center bg-white px-3 py-1 text-black">
                            Proxy Online <v-icon icon="mdi-circle" size="15"
                                    :color="item.proxy_status === 'CONNECTED'||true ? 'green' : 'red'" class="mr-2"></v-icon>
                        </div>
                        </div>
                    </td>
                    <td>
                        <div class="flex items-center">

                            {{ item.modem_name }}
                        </div>
                    </td>
                    <td class="text-center">
                        <div>{{ calculateRemainingTime(item.expired_date) }}</div>
                        <div>Start: {{ formatDate(new Date(item.start_date)) }}</div>
                        <div>Expire: {{ formatDate(new Date(item.expired_date)) }}</div>
                        <!-- {{ item.start_date.split('T')[0].replaceAll('-', '/') + `~` +
                    item.expired_date.split('T')[0].replaceAll('-', '/') }} -->
                    </td>
                    <td>
                        <v-icon icon="mdi-license" color="green"></v-icon>
                        {{ item.package_name }}
                    </td>
                    <td>
                        <!-- <v-icon icon="mdi-alpha-h-circle-outline" color="green"></v-icon> -->
                        HTTP: {{ item.proxy_http_port }} 
                        <br>
                        <!-- <v-icon icon="mdi-alpha-s-circle-outline" color="green"></v-icon> -->
                        SocksV5: {{ item.proxy_sock_port }}
                    </td>
                    <td>
                        <div class=" w-28 text-center" v-if="item.modem_type==='X_PROXY'">
                            <div>
                                HTTP: 
                                {{ item.http_dl_bytes ? item.http_dl_bytes : 0 }} MB
                            </div>
                            <div>
                                SOCKS5:  
                                {{ item.sock_dl_bytes ? item.sock_dl_bytes : 0 }} MB
                            </div>
                        </div>
                        <div class=" w-28 text-center" v-else-if="item.modem_type==='PROXYSMART'">
                            <div>
                                {{ item.dl_bytes ? item.dl_bytes : '0MB' }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="w-32 mx-auto" @click="alert">
                            <div class="text-start flex items-center">
                                <v-icon icon="mdi-account-key" color="green mx-1"></v-icon>
                                {{ (item.auth_user === '' || item.auth_user === null) ? '- - - - - / - - - - - ' :
                    item.auth_user }}
                            </div>
                            <div class="text-start flex items-center">
                                <v-icon icon="mdi-ip" color="green mx-1"></v-icon>
                                <div>
                                    <div v-if="item.auth_ips === '' || item.auth_ips === null">
                                      - - - - - / - - - - -
                                    </div>
                                    <div v-else>
                                      <div v-for="(str, index) in item.auth_ips.split(',')" :key="index">
                                        {{ str }}<br />
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="w-[250px] mx-auto " @click="alert">
                            <div class="text-start flex items-center justify-center">
                                <v-icon icon="mdi-account" color="green  mx-1"></v-icon>
                                {{ item.customer_name }}
                            </div>
                            <div class="text-start flex items-center justify-center">
                                <v-icon icon="mdi-email" color="green  mx-1"></v-icon>
                                {{ item.customer_email }}
                            </div>
                        </div>
                    </td>
                    <td>{{ item.last_change_ip && item.last_change_ip.split('T')[0].replaceAll('-', '/') }}</td>
                    <td class="text-center">
                        {{ item.auto_rotation_time <= 0 ? 'Not defined' : item.auto_rotation_time + ' min' }} </td>
                    <td>
                        <v-menu offset-y location="bottom">
                            <template v-slot:activator="{ props, on, isActive }">
                                <v-btn v-bind="props" v-on="{ ...on }" density="compact"
                                    :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                                    style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                                    Actions
                                    <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                                </v-btn>
                            </template>
                            <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                                style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                                <v-btn v-for="(button, index) in actionButtons" :key="index"
                                    @click="clickAction(item, button)" rounded="0" class="text-center bg-dark text-light "
                                    style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                                    :data-bs-toggle="(button === 'Update'||button === 'Switch Modem')&& 'modal'"
                                    :data-bs-target="
                                    button === 'Update'?'#licenseUpdateModal':
                                    button === 'Switch Modem'?'#modemChangeModal':
                                    ''"
                                    >{{
                    button }}</v-btn>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
            <template #bottom></template>
        </v-data-table>
    </div>
    <div>
        <AddNewOneDialog :selectedServerName="selectedServerName" @close="addNewOneDialogFlag = false"
            :ispName="selectedISPName" @updated="updatedHandler"
            v-if="selectedServerName && selectedServerName !== 'All'" />
        <UpdateInfoDialog :item="selectedItem" @updated="updatedHandler"/>
        <ChangeModemDialog :selectedServerName="selectedServerName" :license_uuid="selectedItem?.uuid"
            @updated="updatedHandler" />
    </div>
</template>

<script setup>
import { ref, defineProps, onMounted, onBeforeUnmount } from 'vue';
import { useAdminLicenseStore } from "@/stores"
import SearchBar from './SearchBar.vue';
import { storeToRefs } from 'pinia';

import AddNewOneDialog from "./Modals/AddNewOneDialog.vue"
import UpdateInfoDialog from "./Modals/UpdateInfoDialog.vue"
import ChangeModemDialog from "./Modals/ChangeModemDialog.vue"

import { watchEffect } from 'vue';
const props = defineProps({
    selectedEmail: {
        type: String, // Assuming user object has a name property
    },
});
const licenseStatusList = ref(['ACTIVE', 'EXPIRED'])    
const selectedLicenseStatus = ref('ACTIVE')
const headers = ref(
    [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Status', align: 'center', key: 'status' },
        { title: 'Modem', align: 'center', key: 'modem_name' },
        { title: 'Remaining/Start/Expire Time', align: 'center', key: 'date' ,minWidth: "250", width: "250" },
        { title: 'Package', align: 'center', key: 'package_name', minWidth: "200", width: "200" },
        { title: 'Proxy Ports', align: 'center', key: 'proxy_ports' , minWidth: "150", width: "150" },
        { title: 'Download', align: 'center', key: 'bytes' },
        { title: 'Auth', align: 'center', key: 'auth' },
        { title: 'Customer', align: 'center', key: 'customer' },
        { title: 'IP Changed', align: 'center', key: 'last_change_ip' },
        { title: 'Auto Rotation Time', align: 'center', key: 'auto_rotation_time' },
        { title: 'Actions', align: 'center', key: 'buttonBar' },
    ]
);
const adminLicenseStore = useAdminLicenseStore()
const { Licenses, loading } = storeToRefs(adminLicenseStore)

const selectedServerName = ref("All")
const addNewOneDialogFlag = ref(false)
const updateInfoDialogFlag = ref(false)
const changeModemDialogFlag = ref(false)
const selectedItem = ref(null)
let count = 0;


const nameSelectedHandeler = (name) => {
    if (name && selectedServerName.value !== name) {
        selectedServerName.value = name
        adminLicenseStore.loadLicenses({
            serverName: name, 
            from: 0, 
            count: 50, 
            licenseStatus: selectedLicenseStatus.value, 
            expiringIn: selectedExpiringMode.value, 
            customerName: selectedCustomerName.value})
        count = 50
    }
}


const actionButtons = ref(
    ["Update", "Change IP", "Switch Modem", "Reboot"]
)
const clickAction = (item, button) => {
    switch (button) {
        case "Update":
            selectedItem.value = item
            updateInfoDialogFlag.value = true
            break
        case "Change IP":
            adminLicenseStore.changeIp(item.uuid)
            break;
        case "Reboot":
            adminLicenseStore.reboot(item.uuid)
            break;
        case "Switch Modem":
            selectedItem.value = item
            changeModemDialogFlag.value = true
            break;

    }
}


const selectedItems = ref([]);

const isSelected = (item) => {
    return selectedItems.value.includes(item);
};

const toggleSelection = (item) => {
    const index = selectedItems.value.indexOf(item);
    if (index === -1) {
        selectedItems.value.push(item);
    } else {
        selectedItems.value.splice(index, 1);
    }
};

const addLicense = () => {
    addNewOneDialogFlag.value = true
}
const deleteLicenses = () => {
    adminLicenseStore.deleteLicenses(selectedItems.value.map(item => item.uuid)).then(() => {
        adminLicenseStore.loadLicenses({
            serverName: selectedServerName.value, 
            from: 0, 
            count: 50, 
            licenseStatus: selectedLicenseStatus.value, 
            expiringIn: selectedExpiringMode.value, 
            customerName: selectedCustomerName.value})
        count = 50
        selectedItems.value = []
    })

}
const updatedHandler = () => {
    updateInfoDialogFlag.value = false
    changeModemDialogFlag.value = false
    addNewOneDialogFlag.value = false
    adminLicenseStore.loadLicenses({
        serverName: selectedServerName.value, 
        from: 0, 
        count: 50, 
        licenseStatus: selectedLicenseStatus.value, 
        expiringIn: selectedExpiringMode.value, 
        customerName: selectedCustomerName.value})
    count = 50
}

const selectedCustomerName = ref(props.selectedEmail ? props.selectedEmail : "All")
const { customers } = storeToRefs(adminLicenseStore)

adminLicenseStore.getCustomers()

const expiringModeList = ref(['All', 'Expiring in 24 hours', 'Expiring in 7 days', 'Expiring in 30 days'])
const selectedExpiringMode = ref('All')
// const searchString = ref('')
const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
  const element = document.getElementById('tablecontainer1').children[0]
  element.addEventListener('scroll', onScroll)
  adminLicenseStore.loadLicenses({
    serverName: selectedServerName.value, 
    from: 0, 
    count: 50, 
    licenseStatus: selectedLicenseStatus.value, 
    expiringIn: selectedExpiringMode.value, 
    customerName: selectedCustomerName.value})
  count = 50
});

let lastScrollLeft = 0;

const onScroll = (event) => {
  const table = event.target;
  if (table.scrollLeft !== lastScrollLeft) {
    lastScrollLeft = table.scrollLeft;
    return; // Ignore horizontal scroll
  }
  if (table.scrollTop + table.clientHeight >= table.scrollHeight) {
    adminLicenseStore.loadLicenses({
        serverName: selectedServerName.value, 
        from: count, 
        count: 20, 
        licenseStatus: selectedLicenseStatus.value, 
        expiringIn: selectedExpiringMode.value, 
        customerName: selectedCustomerName.value})
    count += 20;
  }
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

// const getFilter = (license) => {
//     const diff = (new Date(license.expired_date) - new Date()) / (1000 * 60 * 60);
//     const expiringFlag = selectedExpiringMode.value === 'All' ?
//         true :
//         selectedExpiringMode.value === 'Expiring in 24 hours' ?
//             diff < 24 :
//             selectedExpiringMode.value === 'Expiring in 7 days' ?
//                 diff < 24 * 7 :
//                 selectedExpiringMode.value === 'Expiring in 30 days' ?
//                     diff < 24 * 30 :
//                     false
//     return (license.status.toUpperCase() === selectedLicenseStatus.value.toUpperCase()) &&
//         (selectedCustomerName.value === 'All' || license.customer_email.toUpperCase().includes(selectedCustomerName.value.toUpperCase())) &&
//         (license.uuid.includes(searchString.value) || license.proxy_http_port.toString().includes(searchString.value) || license.proxy_sock_port.toString().includes(searchString.value)) &&
//         expiringFlag
// }

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
}

// watchEffect(() => {
//     if (selectedServerName.value !== '') {
//         Licenses.value = []
//     }
// }, [selectedServerName.value])

watchEffect(() => {
    adminLicenseStore.loadLicenses({
        serverName: selectedServerName.value, 
        from: 0, 
        count: 50, 
        licenseStatus: selectedLicenseStatus.value, 
        expiringIn: selectedExpiringMode.value, 
        customerName: selectedCustomerName.value})
    count = 50
})

const calculateRemainingTime = (expiringTime) => {
    const now = new Date();
    const expirationDate = new Date(expiringTime);
    const timeDifference = expirationDate.getTime() - now.getTime();
    if(timeDifference<0){
        return "Expired"
    }
    // Convert milliseconds to days, hours, minutes, and seconds
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const str = (days ? `${days} Days, ` : '') +
        (hours ? `${hours} Hours, ` : '') +
        (minutes ? `${minutes} Mins ` : '')
    return str
}
</script>

<style scoped>
.custom-style {
    width: -webkit-fill-available; /* Safari/WebKit */
    height: -webkit-fill-available; /* Safari/WebKit */
    width: -moz-available; /* Firefox */
    height: -moz-available; /* Firefox */
    width: fill-available; /* Standard syntax, for future compatibility */
    height: fill-available; /* Standard syntax, for future compatibility */
}
</style>