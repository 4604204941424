<template>
  <data v-if="user">
      <div class="flex justify-between items-center pb-4">
        <div class="flex flex-wrap">
            <div class="w-56 pr-5 text-xs text-black">
                <p class='py-1'>Select Status</p>

                <v-select v-model="selectedLicenseStatus" :items="licenseStatusList" density="compact"
                    variant="outlined" placeholder="Select License Status" rounded="0"
                    style="padding-top: 0px;padding-bottom: 0px;" class="bg-white text-black">

                </v-select>
            </div>
            <div class="w-56 pr-5 text-xs text-black">
                <p class='py-1'>Select View Method</p>

                <v-select v-model="selectedView" :items="viewList" density="compact"
                    variant="outlined" placeholder="Select License Status" rounded="0"
                    style="padding-top: 0px;padding-bottom: 0px;" class="bg-white text-black">

                </v-select>
            </div>
            <div @click="toggleSelection()" class="ml-auto align-self-center flex flex-row mt-[20px] cursor-pointer">
                <input :checked="expiringIn" class="form-check-input shadow-none cursor-pointer mr-[5px]" type="checkbox" />
                <span>Expiring in 24 hours</span>
            </div>
        </div>
      </div>
      <div v-if="selectedView === 'Table'" class="px-4 py-4 bg-white">
          
          <v-data-table :headers="headers" items-per-page="-1"
              :items="numberedproxies.filter(item => (item.status.toUpperCase() === selectedLicenseStatus.toUpperCase() && (!expiringIn || calculateRemainingTime(item.expired_date).isOne)))"
              item-key="name" class=" bg-gray border text-black min-h-[500px]" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">

              <template v-slot:[`item.no`]="{ index }">
                  {{ index + 1 }}
              </template>
              <template v-slot:[`item.note`]="{item}">
                <div class="flex flex-row text-sm ">
                    <div v-if="item.note" class="flex justify-between items-center bg-white px-3 w-[150px] py-1 text-black">
                        <pre class="text-green-600 font-bold w-full">{{item.note}}</pre>
                    </div>
                    <div class="flex flex-row items-center">
                        <v-icon v-if="item.note" icon="mdi-square-edit-outline"  class="cursor-pointer my-[1px] mx-4"
                            data-bs-toggle="modal" color="light-green mx-1"
                            data-bs-target="#noteModal"
                            @click="proxyAction(item, 'UPDATENOTE')"
                        ></v-icon>
                        <v-icon v-else icon="mdi-plus-thick"  class="cursor-pointer my-[1px] mx-4"
                            data-bs-toggle="modal" color="light-green mx-1"
                            data-bs-target="#noteModal"
                            @click="proxyAction(item, 'ADDNOTE')"
                        ></v-icon>
                    </div>
                  </div>
              </template>
              <template v-slot:[`item.coupon_code`]="{item}">
                <div class="flex flex-row text-sm ">
                    <div class="pl-4 text-green-600">{{item?.coupon_code}}</div>
                    <v-icon v-if="item?.coupon_code" icon="mdi-square-edit-outline"  class="cursor-pointer my-[1px] mx-4"
                            data-bs-toggle="modal" color="light-green mx-1"
                            data-bs-target="#couponModal"
                            @click="proxyAction(item, 'EDITCOUPON')"
                    ></v-icon>
                    <v-icon v-else icon="mdi-plus-thick"  class="cursor-pointer my-[1px] mx-4"
                        data-bs-toggle="modal" color="light-green mx-1"
                        data-bs-target="#couponModal"
                        @click="proxyAction(item, 'EDITCOUPON')"
                    ></v-icon>
                </div>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                  <div class="space-y-1 py-1 font-semibold ">
                      <div class="flex justify-between items-center bg-white px-3 py-1 text-black">
                          Server Online <v-icon icon="mdi-circle" size="15"
                              :color="item.modem_conn_status ? 'green' : 'red'" class="mr-2"></v-icon>
                      </div>
                      <div class="flex justify-between items-center bg-white px-3 py-1 text-black">
                          Proxy Online <v-icon icon="mdi-circle" size="15"
                              :color="(item.proxy_status === 'CONNECTED' || true)? 'green' : 'red'" class="mr-2"></v-icon>
                      </div>
                  </div>
              </template>
              <template v-slot:[`item.expired_date`]="{ item }">
                  <div v-if="calculateRemainingTime(item.expired_date).isOne" class="text-red-600 font-bold">Remaining: {{ calculateRemainingTime(item.expired_date).time }}</div>
                  <div v-else class="text-green-600 font-bold">Remaining: {{ calculateRemainingTime(item.expired_date).time }}</div>
                  <div>Start: {{ formatDate(new Date(item.start_date)) }}</div>
                  <div>Expire: {{ formatDate(new Date(item.expired_date)) }}</div>
              </template>
              <template v-slot:[`item.proxy_ports`]="{ item }">
                  <div>
                      Proxy: {{ item.modem_name }}
                  </div>
                  <div class="flex items-center justify-between">
                      HTTP: {{ `${item.host_domain}:${item.http_port}` }}
                      <div>

                          <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                              @click="copyText(`${item.host_domain}:${item.http_port}${item.authentication_user === '' ? '' : ':' + item.authentication_user}`)"
                              color="light-green">
                              <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                          </v-btn>
                          <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                              @click="copyText(`${item.authentication_user === '' ? '' : item.authentication_user + '@'}${item.host_domain}:${item.http_port}`)"
                              color="light-green">
                              <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                          </v-btn>
                      </div>
                  </div>
                  <div class="flex items-center justify-between">
                      SocksV5: {{ `${item.host_domain}:${item.socket_port}` }}
                      <div>

                          <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                              @click="copyText(`${item.host_domain}:${item.socket_port}${item.authentication_user === '' ? '' : ':' + item.authentication_user}`)"
                              color="light-green">
                              <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                          </v-btn>
                          <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                              @click="copyText(`${item.authentication_user === '' ? '' : item.authentication_user + '@'}${item.host_domain}:${item.socket_port}`)"
                              color="light-green">
                              <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                          </v-btn>
                      </div>
                  </div>
              </template>
              <template v-slot:[`item.ip_status`]="{ item }">
                  <div class="flex flex-col">
                      <div :class="ipStatus[item.uuid] ? 'text-light-green' : 'text-red'"
                          class="font-bold flex justify-center items-center">
                          <div>{{ item.public_ip ? item.public_ip : '* . * . * . * ' }}</div>
                          <v-btn density="compact" variant="text" icon="mdi-refresh" color="light-green"
                              @click="refreshIp(item.uuid)"></v-btn>
                      </div>
                      <v-btn :disabled="!ipStatus[item.uuid]" class="font-bold bg-dark text-light" variant="outliend"
                          style="outline:none; font-size: 12px;" density="compact" @click="changeIp(item.uuid)">
                          Change IP
                      </v-btn>
                      <div v-if="item.m_type === 'PROXYSMART'" :style="checkDownloadLimit(item, item.dl_bytes) ? 'color: red' : ''">
                      {{ item.dl_bytes && item.dl_bytes !== "NaN" ? item.dl_bytes : '0MB'}} 
                      </div>
                      <div v-else-if="item.m_type === 'X_PROXY'">
                          <div :style="checkDownloadLimit(item, item.dl_bytes) ? 'color: red' : ''">
                              HTTP: 
                              {{ item.dl_bytes }} MB
                          </div>
                          <div :style="checkDownloadLimit(item, (item.counter_dl_used_bytes / (1024 * 1024))) ? 'color: red' : ''">
                              SOCKS5:  
                              {{ (item.counter_dl_used_bytes / (1024 * 1024)).toFixed(2) }} MB
                          </div>
                      </div>
                  </div>
              </template>
              <template v-slot:[`item.buttonBar`]="{ item }">
                  <v-menu offset-y location="bottom">
                      <template v-slot:activator="{ props, on, isActive }">
                          <v-btn v-bind="props" v-on="{ ...on }" density="compact"
                              :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                              style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                              Actions
                              <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                          </v-btn>
                      </template>
                      <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                          style="border-top-left-radius: 0px;border-top-right-radius: 0px">

                          <template v-for="(button, index) in item.m_type === 'X_PROXY' ? actionButtons : actionButtonsSmart" :key="index">
                              <!-- Check if button is "abc" -->

                              <v-btn @click="proxyAction(item, button)" rounded="0"
                                  class="bg-dark text-light text-center "
                                  style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                                  type="button"
                                  :data-bs-toggle="(button === 'AUTO ROTATION' || button === 'AUTHENTICATION' || button === 'EXTEND' || button === 'OS Spoofing') && 'modal'"
                                  :data-bs-target="button === 'AUTO ROTATION' ? '#autoRotationDialog' :
      button === 'AUTHENTICATION' ? '#authenticationModal' :
          button === 'EXTEND' ? '#extendLicenseModal' : button === 'OS Spoofing' ? '#osSpoofingModal' :
              ''">
                                  {{ button }}
                              </v-btn>
                              
                          </template>
                      </v-list>
                  </v-menu>
              </template>
              <template v-slot:[`item.auto_extend`]="{ item }" >
                  <div class="flex flex-col">
                      <div class="flex justify-center my-1">
                          <v-switch density="dense" color="light-green" v-model="item.auto_extend" label-placement="left"
                              label="" @click="updateAutoExtend(item)"></v-switch>
                      </div>
                      {{ item.auto_rotation_time <= 0 ? 'Not defined' : item.auto_rotation_time + ' min' }}
                  </div>
              </template>
              <template v-slot:[`item.auth`]="{ item }">
                  <div class="flex items-center ">
                      <div class="w-32 mx-auto" @click="alert">
                          <div class="text-start flex items-center">
                              <v-icon icon="mdi-account-key" color="light-green mx-1"></v-icon>
                              {{ (item.authentication_user === '' || item.authentication_user === null) ? `- - - - - /
                              - -
                              - - - ` : item.authentication_user }}
                          </div>
                          <div class="text-start flex items-center">
                              <v-icon icon="mdi-ip" color="light-green mx-1"></v-icon>
                              {{ (item.authorization_ips === "" || item.authorization_ips === null) ? `- - - - - / - -
                              - -
                              - ` : item?.authorization_ips?.replace(/,/g, ', ') }}
                          </div>
                      </div>
                      <div class="flex flex-col items-end">
                          <v-icon icon="mdi-application-edit"  class="cursor-pointer my-[1px]"
                          data-bs-toggle="modal" color="light-green mx-1"
                          data-bs-target="#authenticationModal"
                          @click="proxyAction(item, 'AUTHENTICATION')"
                          ></v-icon>
                          <div v-if="item.m_type === 'PROXYSMART'" class="w-full flex flex-col items-center justify-center my-[1px]">
                              <span class=" text-[10px] text-green-500">OpenVPN:</span>
                              <img src="../../../assets/openVPN.png" :alt="proxy" @click="downloadFile(`${item.domain}/get_vpn_profile/${item.port_id}.ovpn`)" class=" cursor-pointer"/>
                          </div>
                      </div>
                  </div>
              </template>
              <!-- <template v-slot:[`item.auto_rotation_time`]="{ item }">
                  {{ item.auto_rotation_time <= 0 ? 'Not defined' : item.auto_rotation_time + ' min' }} </template> -->
                      <!-- Define other custom cell components using similar approach -->
          </v-data-table>
      </div>
      <div v-else-if="selectedView === 'Card'">
          <v-container style="padding:0px">
              <v-row  class="flex gap-5 pb-[30px]">
                  <v-col v-for="(proxy, i) in numberedproxies.filter(item => (item.status.toUpperCase() === selectedLicenseStatus.toUpperCase() && (!expiringIn || calculateRemainingTime(item.expired_date).isOne)))" :key="i" cols="auto">
                      <v-card variant="" class="border" rounded="0" width="400px" height="100%" >
                          <v-card-item>
                              <div class="flex flex-col gap-y-6">
                                  <div class="flex flex-col text-sm ">
                                    <div class="flex flex-row items-center">
                                        <span class="text-[16px] font-medium">Note:</span>
                                        <v-icon v-if="proxy.note" icon="mdi-square-edit-outline"  class="cursor-pointer my-[1px] mx-4"
                                            data-bs-toggle="modal" color="light-green mx-1"
                                            data-bs-target="#noteModal"
                                            @click="proxyAction(proxy, 'UPDATENOTE')"
                                        ></v-icon>
                                        <v-icon v-else icon="mdi-plus-thick"  class="cursor-pointer my-[1px] mx-4"
                                            data-bs-toggle="modal" color="light-green mx-1"
                                            data-bs-target="#noteModal"
                                            @click="proxyAction(proxy, 'ADDNOTE')"
                                        ></v-icon>
                                    </div>
                                    <div v-if="proxy.note" class="flex justify-between items-center bg-white px-3 py-1 text-black">
                                        <pre  class=" text-green-600 w-full">{{proxy.note}}</pre>
                                    </div>
                                  </div>
                                  <div class="flex flex-row text-sm ">
                                    <span class="text-[16px] font-medium pb-1">Coupon Code:</span>
                                    <div class="pl-4 text-green-600">{{proxy?.coupon_code}}</div>
                                    <v-icon v-if="proxy?.coupon_code" icon="mdi-square-edit-outline"  class="cursor-pointer my-[1px] mx-4"
                                            data-bs-toggle="modal" color="light-green mx-1"
                                            data-bs-target="#couponModal"
                                            @click="proxyAction(proxy, 'EDITCOUPON')"
                                    ></v-icon>
                                    <v-icon v-else icon="mdi-plus-thick"  class="cursor-pointer my-[1px] mx-4"
                                        data-bs-toggle="modal" color="light-green mx-1"
                                        data-bs-target="#couponModal"
                                        @click="proxyAction(proxy, 'EDITCOUPON')"
                                    ></v-icon>
                                  </div>
                                  <div class="flex flex-col text-sm ">
                                    <span class="text-[16px] font-medium pb-1">Server Status:</span>
                                    <div class="flex gap-x-2 justify-between py-1 font-semibold ">
                                      <div class="flex justify-between items-center bg-white px-3 py-1 text-black">
                                          Server Online <v-icon icon="mdi-circle" size="15"
                                              :color="proxy.modem_conn_status ? 'green' : 'red'" class="mr-2"></v-icon>
                                      </div>
                                      <div class="flex justify-between items-center bg-white px-3 py-1 text-black">
                                          Proxy Online <v-icon icon="mdi-circle" size="15"
                                              :color="(proxy.proxy_status === 'CONNECTED' || true)? 'green' : 'red'" class="mr-2"></v-icon>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="flex flex-col text-sm ">
                                    <span class="text-[16px] font-medium pb-1">Port(HTTP/SOCKS5):</span>
                                    <div>
                                      Proxy: {{ proxy.modem_name }}
                                    </div>
                                    <div class="flex items-center justify-between">
                                        HTTP: {{ `${proxy.host_domain}:${proxy.http_port}` }}
                                        <div>
                
                                            <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                                                @click="copyText(`${proxy.host_domain}:${proxy.http_port}${proxy.authentication_user === '' ? '' : ':' + proxy.authentication_user}`)"
                                                color="light-green">
                                                <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                                            </v-btn>
                                            <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                                                @click="copyText(`${proxy.authentication_user === '' ? '' : proxy.authentication_user + '@'}${proxy.host_domain}:${proxy.http_port}`)"
                                                color="light-green">
                                                <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between">
                                        SocksV5: {{ `${proxy.host_domain}:${proxy.socket_port}` }}
                                        <div>
                
                                            <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                                                @click="copyText(`${proxy.host_domain}:${proxy.socket_port}${proxy.authentication_user === '' ? '' : ':' + proxy.authentication_user}`)"
                                                color="light-green">
                                                <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                                            </v-btn>
                                            <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
                                                @click="copyText(`${proxy.authentication_user === '' ? '' : proxy.authentication_user + '@'}${proxy.host_domain}:${proxy.socket_port}`)"
                                                color="light-green">
                                                <v-icon icon="mdi-pencil-box-multiple-outline" size="17"></v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                  </div>
                                  <div class="flex flex-row items-center text-sm">
                                    <span class="text-[16px] font-medium pr-3">Auth:</span>
                                    <div class="flex items-center ">
                                      <div class="" @click="alert">
                                          <div class="text-start flex items-center">
                                              <v-icon icon="mdi-account-key" color="light-green mx-1"></v-icon>
                                              {{ (proxy.authentication_user === '' || proxy.authentication_user === null) ? `- - - - - /
                                              - -
                                              - - - ` : proxy.authentication_user }}
                                          </div>
                                          <div class="text-start flex items-center">
                                              <v-icon icon="mdi-ip" color="light-green mx-1"></v-icon>
                                              {{ (proxy.authorization_ips === '' || proxy.authorization_ips === null) ? `- - - - - / - -
                                              - -
                                              - ` : proxy?.authorization_ips?.replace(/,/g, ', ') }}
                                          </div>
                                      </div>
                                      <div class="flex flex-row pl-4 items-end">
                                          <v-icon icon="mdi-application-edit"  class="cursor-pointer my-[1px]"
                                          data-bs-toggle="modal" color="light-green mx-1"
                                          data-bs-target="#authenticationModal"
                                          @click="proxyAction(proxy, 'AUTHENTICATION')"
                                          ></v-icon>
                                          <div v-if="proxy.m_type === 'PROXYSMART'" class="w-full flex items-center flex-col justify-center m-[1px]">
                                              <span class=" text-[10px] text-green-500">OpenVPN:</span>
                                                <img src="../../../assets/openVPN.png" :alt="proxy" @click="downloadFile(`${proxy.domain}/get_vpn_profile/${proxy.port_id}.ovpn`)" class=" cursor-pointer"/>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="flex flex-row items-center justify-between">
                                    <div class="flex flex-row items-center">
                                      <span class="text-[16px] font-medium pr-3">Auto Extend:</span>
                                      <div class="flex ">
                                        <v-switch density="dense" color="light-green" v-model="proxy.auto_extend" label-placement="left"
                                            label="" @click="updateAutoExtend(proxy)"></v-switch>
                                      </div>
                                    </div>
                                    <div>
                                      <span class="text-[16px] font-medium pr-3">Rotation Time:</span>
                                      {{ proxy.auto_rotation_time <= 0 ? 'Not defined' : proxy.auto_rotation_time + ' min' }}
                                    </div>
                                  </div>
                                  <div class="flex flex-col">
                                    <div v-if="calculateRemainingTime(proxy.expired_date).isOne" class="flex flex-row items-center">
                                        <span class="text-[18px] text-red-600 font-extrabold pr-5 min-w-[130px]">Remaining:</span>
                                        <div class="font-extrabold text-[18px] text-red-600">{{ calculateRemainingTime(proxy.expired_date).time }}</div>
                                    </div>
                                    <div v-else class="flex flex-row items-center">
                                        <span class="text-[18px] text-green-600 font-extrabold pr-5 min-w-[130px]">Remaining:</span>
                                        <div class="font-extrabold text-[18px] text-green-600">{{ calculateRemainingTime(proxy.expired_date).time }}</div>
                                    </div>
                                    <div class="flex flex-row items-center">
                                      <span class="text-[16px] font-medium pr-5 min-w-[130px]">Start Time:</span>
                                      <div>{{ formatDate(new Date(proxy.start_date)) }}</div>
                                    </div>
                                    <div class="flex flex-row items-center ">
                                      <span class="text-[16px] font-medium pr-5 min-w-[130px]">Expire Time:</span>
                                      <div>{{ formatDate(new Date(proxy.expired_date)) }}</div>
                                    </div>
                                  </div>
                                  <div class="flex flex-col gap-3">
                                    <div class="flex flex-row items-center">
                                      <span class="text-[16px] font-medium pr-5 min-w-[130px]">IP Status:</span>
                                      <div :class="ipStatus[proxy.uuid] ? 'text-light-green' : 'text-red'"
                                          class="font-bold flex justify-center items-center">
                                          <div>{{ proxy.public_ip ? proxy.public_ip : '* . * . * . * ' }}</div>
                                          <v-btn density="compact" variant="text" icon="mdi-refresh" color="light-green"
                                              @click="refreshIp(proxy.uuid)"></v-btn>
                                          <v-btn :disabled="!ipStatus[proxy.uuid]" class="font-bold bg-dark text-light ml-3 p-5" variant="outliend"
                                            style="outline:none; font-size: 12px;" density="compact" @click="changeIp(proxy.uuid)">
                                            Change IP
                                        </v-btn>
                                      </div>
                                    </div>
                                    <div class="flex flex-row items-center ">
                                      <span class="text-[16px] font-medium pr-5 min-w-[130px]">Download:</span>
                                      <div v-if="proxy.m_type === 'PROXYSMART'" :style="checkDownloadLimit(proxy, proxy.dl_bytes) ? 'color: red' : ''">
                                        {{ proxy.dl_bytes && proxy.dl_bytes !== "NaN" ? proxy.dl_bytes : '0MB'}} 
                                      </div>
                                      <div v-else-if="proxy.m_type === 'X_PROXY'">
                                          <div :style="checkDownloadLimit(proxy, proxy.dl_bytes) ? 'color: red' : ''">
                                              HTTP: 
                                              {{ proxy.dl_bytes }} MB
                                          </div>
                                          <div :style="checkDownloadLimit(proxy, (proxy.counter_dl_used_bytes/(1024 * 1024))) ? 'color: red' : ''">
                                              SOCKS5:  
                                              {{ (proxy.counter_dl_used_bytes / (1024 * 1024)).toFixed(2) }} MB
                                          </div>
                                      </div>
                                    </div>
                                  </div>

                              </div>
                          </v-card-item>

                          <v-card-actions>
                            <v-list class="flex gap-1 flex-wrap px-0 py-0  z-0 w-full customlist"
                            style="border-top-left-radius: 0px;border-top-right-radius: 0px">

                              <template v-for="(button, index) in proxy.m_type === 'X_PROXY' ? actionButtons : actionButtonsSmart" :key="index">
                                  <!-- Check if button is "abc" -->

                                  <v-btn @click="proxyAction(proxy, button)" rounded="1"
                                      class="bg-dark text-light text-center ms-0 "
                                      style="font-size: 10px; width: 110px; outline: none; border-bottom: 1px solid #345;"
                                      type="button"
                                      :data-bs-toggle="(button === 'AUTO ROTATION' || button === 'AUTHENTICATION' || button === 'EXTEND' || button === 'OS Spoofing') && 'modal'"
                                      :data-bs-target="button === 'AUTO ROTATION' ? '#autoRotationDialog' :
                                        button === 'AUTHENTICATION' ? '#authenticationModal' :
                                            button === 'EXTEND' ? '#extendLicenseModal' : button === 'OS Spoofing' ? '#osSpoofingModal' :
                                                ''">
                                      {{ button }}
                                  </v-btn>
                                  
                              </template>
                            </v-list>
                          </v-card-actions>
                      </v-card>
                  </v-col>
              </v-row>
          </v-container>
      </div>

  </data>
  <div>
      <AutoRotationDialog :uuid="currentItem ? currentItem.uuid : null"
          :min_time_change_ip="currentItem ? currentItem.min_time_change_ip : null" :rotation_time="currentItem?.auto_rotation_time" @close="updatedHandler" />
      <AuthenticateDialog :license="currentItem ? currentItem : null" @close="updatedHandler" />
      <ExtendLicenseDialog :license="currentItem ? currentItem : null" @close="updatedHandler" />
      <OSSpoofingDialog :license="currentItem ? currentItem : null" @close="updatedHandler" />
      <NoteDialog :license="currentItem ? currentItem : null" @close="updatedHandler" />
      <CouponDialog :license="currentItem ? currentItem : null" @close="updatedHandler" />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import AutoRotationDialog from "@/components/Customer/MyProxy/Modals/AutoRotationModal.vue"
import AuthenticateDialog from "@/components/Customer/MyProxy/Modals/AuthenticationModal.vue"
import NoteDialog from "@/components/Customer/MyProxy/Modals/NoteModal.vue"
import CouponDialog from "@/components/Customer/MyProxy/Modals/CouponModal.vue"
import ExtendLicenseDialog from "@/components/Customer/MyProxy/Modals/ExtendLicenseModal.vue"
import OSSpoofingDialog from "@/components/Customer/MyProxy/Modals/OSSpoofingModal.vue"
import { useAccountStore, useAlertStore, useProxyStore } from "@/stores"
import copy from "clipboard-copy"
// import CheckboxYes from "@/assets/check_yes_x.png"
// import CheckboxNo from "@/assets/check_no_x.png"
import { storeToRefs } from 'pinia';
import { watchEffect } from 'vue';
import { connectToWebSocket } from "@/stores/socket.store"
import { fetchWrapper } from '@/helpers';


const selectedItems = ref([]);
const currentItem = ref(null)
const licenseStatusList = ref(['Active', 'Expired'])
const viewList = ref(['Card', 'Table']);
const expiringIn = ref(false);

const ipStatus = ref({})

const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
computedHeight.value = window.innerHeight - offsetHeight;
};
const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
const downloadFile = async (fileUrl) => {
    const backendUrl = `${baseUrl}/account/download?url=${encodeURIComponent(fileUrl)}`;

    try {
    // Call the backend proxy with the dynamic URL as a query parameter

    const response = await fetch(backendUrl, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Get the file as a blob
    const blob = await response.blob();

    // Create a URL for the blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', 'vpn_profile.ovpn'); // Set the file name

    // Append the link to the body and trigger the click
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);

    // Revoke the object URL to release memory
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

const checkDownloadLimit = (item, value) => {
    if(item.m_type==='X_PROXY') {
        console.log(item)
        if(parseFloat(item.counter_dl_limit_quota) !== 0 && item.counter_dl_limit_by?.toUpperCase() === 'DAILY' && parseFloat(item.counter_dl_limit_quota) <= parseFloat(value)) {
            return true
        }
        else return false
    } else {
        if(item.quota_dir === 'in' && item.quota_type === "daily" && value !== 0 && value !== '0') {
            const regex = /(\d+(\.\d+)?)\s*(\w+)/;
            const matches = value.match(regex);
            console.log(value)
            if(matches[3]?.toUpperCase() === 'MB' && parseFloat(matches[1]) >= parseFloat(item.band_amount)) return true
            else if(matches[3]?.toUpperCase() === 'GB' && (parseFloat(matches[1]) * 1024) >= parseFloat(item.band_amount)) return true
            else return false

        } else return false
    }
}

onMounted(() => {
window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
window.removeEventListener('resize', handleResize);
});

const toggleSelection = () => {
    expiringIn.value = !expiringIn.value
};
const proxyStore = useProxyStore()
const proxyAction = (item, button) => {
  currentItem.value = item
  switch (button) {
      case "STATUS API":
          window.open(`${process.env.VUE_APP_VUE_API_URL}/proxy/status?license=` + item.uuid)
          break
      case "LINK CHANGE IP":
          copyText(`${process.env.VUE_APP_VUE_CUSTOM_API_URL}/proxy/change-ip?license=` + item.uuid)
          break
      case "AUTO ROTATION":
          // autoRotationDialog.value = true
          // alert()
          break
      case "REBOOT DEVICE":
          proxyStore.rebootDevice(item.uuid)
          break;
      case "AUTHENTICATION":
          break
      case "UPDATENOTE":
          break
      case "ADDNOTE":
          break
      case "EDITCOUPON":
          break
      case "EXTEND":
          // proxyStore.extendLicense(item.uuid)
          break
      case "OS Spoofing":
        break
  }
}
const actionButtons = ref(
  ["STATUS API", "LINK CHANGE IP", "AUTO ROTATION", "REBOOT DEVICE", "EXTEND"]
)
const actionButtonsSmart = ref(
    ["STATUS API", "LINK CHANGE IP", "AUTO ROTATION", "REBOOT DEVICE", "EXTEND", "OS Spoofing"]
)
const copyText = (text) => {
  copy(text)
  const alertStore = useAlertStore()
  alertStore.success("Copied");
}

const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);

const headers = ref(
  [
      { title: 'No', align: 'center', key: 'no' },
      { title: 'Note', align: 'left', key: 'note'},
      { title: 'Coupon Code', align: 'center', key: 'coupon_code' },
      { title: 'Status', align: 'center', key: 'status' },
      { title: 'Port (HTTP / SOCKS5)', align: 'center', key: 'proxy_ports', minWidth: "300", width: "300" },
      { title: 'Auth', align: 'center', key: 'auth', minWidth: "250", width: "250" },
      { title: 'Auto Extend / Rotation Time', align: 'center', key: 'auto_extend', minWidth: "150", width: "150" },  
      // { title: 'Remaining', align: 'center', key: 'date', minWidth: "200", width: "200" },
      { title: 'Start/Expire Time', align: 'center', key: 'expired_date', minWidth: "200", width: "200" },
      { title: 'IP Status / Download', align: 'center', key: 'ip_status' },
      // { title: 'OpenVPN Connection File', align: 'center', key: 'openVPN'},
      // { title: 'Auto Rotation Time', align: 'center', key: 'auto_rotation_time' },
      { title: 'Actions', align: 'center', key: 'buttonBar' },
  ]
);

const { proxyList } = storeToRefs(proxyStore)
const numberedproxies = ref([])

const selectedLicenseStatus = ref("")
const selectedView = ref("")
watchEffect(() => {
  if (user && user.value && user.value.user_name) {
      proxyStore.getProxyList()
      connectToWebSocket(user.value.user_name)
  }
}, [])
watchEffect(() => {

  numberedproxies.value = proxyList.value.map((item, index) => {
      if (ipStatus.value[item.uuid] === undefined)
          ipStatus.value = { ...ipStatus.value, [item.uuid]: true }
      return { ...item, no: index + 1, buttonBar: index }; // Add 'no' field with 1-based numbering
  })
}, [proxyList.value])
watchEffect(() => {
  selectedLicenseStatus.value = licenseStatusList.value[0]
  selectedView.value = viewList.value[0]
}, [licenseStatusList.value])
const changeIp = (uuid) => {
  ipStatus.value = {
      ...ipStatus.value,
      [uuid]: false
  }
  proxyStore.changeIp(uuid).then(res => {
      proxyStore.getProxyList()
      ipStatus.value = {
          ...ipStatus.value,
          [uuid]: true
      }
      setTimeout(() => {
          refreshIp(uuid);
      }, 2000);
      refreshIp(uuid);
  })
}

const refreshIp = (uuid) => {
  proxyStore.refreshIp(uuid).then(res => {
      if (res) {
          numberedproxies.value = numberedproxies.value.map(proxy => {
              if (proxy && proxy.uuid === uuid) {
                  proxy.public_ip = res
              }
              return proxy

          })
      }

  })
}

const updatedHandler = () => {
  proxyStore.getProxyList()
}
const updateAutoExtend = (item) => {
  let targetFlag = !item.auto_extend
  proxyStore.updateAutoExtend(item.uuid, targetFlag).then(res => {
      if (res === false)
          proxyStore.getProxyList()
  })
}
const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
}
const calculateRemainingTime = (expiringTime) => {
    const now = new Date();
    const expirationDate = new Date(expiringTime);
    const timeDifference = expirationDate.getTime() - now.getTime();
    if(timeDifference<0){
        return "Expired"
    }
    // Convert milliseconds to days, hours, minutes, and seconds
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const str = (days ? `${days} Days, ` : '') +
        (hours ? `${hours} Hours, ` : '') +
        (minutes>=0 ? `${minutes} Mins ` : '')
    return { time: str, isOne: days ? false : true }
}

</script>
<style>
::-webkit-scrollbar {
  background: #78ffe7;
}
</style>